var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticClass: "mb20",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "max-height": "400",
            "tooltip-effect": "dark",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm.handle === "wu"
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "优惠券名称", "min-width": "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "money", label: "优惠券面值", "min-width": "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "minPrice", label: "最低消费额", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.minPrice === 0
                            ? "不限制"
                            : scope.row.minPrice
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "有效期限", "min-width": "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.isFixedTime
                            ? scope.row.useStartTime +
                                " 一 " +
                                scope.row.useEndTime
                            : scope.row.day + "天"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "剩余数量", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          !scope.row.isLimited ? "不限量" : scope.row.lastTotal
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.handle === "send"
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "70", fixed: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["admin:coupon:user:receive"],
                                  expression: "['admin:coupon:user:receive']",
                                },
                              ],
                              staticClass: "mr10",
                              on: {
                                click: function ($event) {
                                  return _vm.sendGrant(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("发送")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1680058347
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.handle === "wu"
        ? _c(
            "div",
            { staticClass: "dialog-footer-inner btnBottom" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.dialogcloseFun } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.ok },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }