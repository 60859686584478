var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", { staticClass: "header clearfix" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "el-form",
              { attrs: { inline: "", "label-width": "120px", size: "small" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "商品搜索:" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入商品名称" },
                        on: {
                          input: function ($event) {
                            return _vm.onInput($event)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tableFrom,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "tableFrom.name",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "平台商品分类:" } },
                  [
                    _c("el-cascader", {
                      staticClass: "selWidth",
                      attrs: {
                        options: _vm.categoryList,
                        props: _vm.props,
                        filterable: "",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "categoryId", $$v)
                        },
                        expression: "tableFrom.categoryId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "商户商品分类:" } },
                  [
                    _c("el-cascader", {
                      staticClass: "selWidth",
                      attrs: {
                        options: _vm.mercategoryList,
                        props: _vm.props,
                        filterable: "",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.cateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "cateId", $$v)
                        },
                        expression: "tableFrom.cateId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "商品状态:" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "selWidth",
                        attrs: { clearable: "", placeholder: "请选择商品状态" },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.isShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "isShow", $$v)
                          },
                          expression: "tableFrom.isShow",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "上架", value: "1" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "下架", value: "0" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm.handleNum === "many"
            ? _c("el-table-column", {
                key: "2",
                attrs: { width: "55" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              slot: "reference",
                              value:
                                _vm.isChecked &&
                                _vm.checkedPage.indexOf(_vm.tableFrom.page) >
                                  -1,
                            },
                            on: { change: _vm.changeType },
                            slot: "reference",
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              value: _vm.checkedIds.indexOf(scope.row.id) > -1,
                            },
                            on: {
                              change: function (v) {
                                return _vm.changeOne(v, scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3985770792
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.handleNum !== "many"
            ? _c("el-table-column", {
                key: "1",
                attrs: { width: "55" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: scope.row.id },
                              nativeOn: {
                                change: function ($event) {
                                  return _vm.getTemplateRow(scope.row)
                                },
                              },
                              model: {
                                value: _vm.templateRadio,
                                callback: function ($$v) {
                                  _vm.templateRadio = $$v
                                },
                                expression: "templateRadio",
                              },
                            },
                            [_vm._v(" ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2617125520
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品图", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview line-heightOne" },
                      [
                        _c("el-image", {
                          attrs: {
                            src: scope.row.image,
                            "preview-src-list": [scope.row.image],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "商品名称", "min-width": "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "categoryName",
              label: "商品分类",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.isShow ? "上架" : "下架")),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mb20 inline-block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.handleNum === "many"
        ? _c(
            "div",
            { staticClass: "right-align inline-block" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.ok },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }